table {
  border: .1px solid #CCC;
}

.table tr td {
  height: 50px;
}

.button {
  padding: .1em .5em;
  cursor: pointer;
  background-color: blue;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.button.pop {
  width: 80px;
  margin: auto;
}

.button:hover {
  color: white;
  background-color: rgb(0, 0, 175);
  text-decoration: none !important;
}

.divider {
  height: 10px;
}

.trash {
  margin-top: .7em;
}

@media all and (min-width: 400px){
  table,tr, td, tbody, td p table div, table table{
      width:100%!important;
      /* float:left;
      clear:both;
      display:block;
      text-align:center; */
  }

  table {
    max-width: 500px;
    margin: auto;
  }

  .table tr td {
    height: inherit;
  }

  .trash {
    margin-top: inherit;
  }
}

@media all and (min-width: 460px){
  .table tr td {
    min-width: 80px;
  }
}

/* POPUP */
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255,255,255,0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.button:hover {
  background: red;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }

  .popup{
    width: 70%;
  }
}