.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.navlink {
  color: #fff;
  margin-right: .5em;
  text-decoration: none !important;
  padding: 10px;
}

.navlink:hover {
  color: #fff;
}

.navlink.first {
  margin-left: .5em;
}

.primary-bg {
  background: var(--primary-color);
}

.logo {
  margin-top: -3px;
}

.navlink.active {
  font-weight: bold;
  background: #007bff;
}
